import React from "react"
import Card from "../components/atoms/Card"

export default function CardsSorter({
  contactName,
  contactRole,
  contactImage,
  contactDiscipline,
  contactEmail,
  contactTeam,
  contactManager,
  contactDisciplineHead,
  showDiscipleHeadBorder,
  showManagerBorder,
  currentTeam,
  contactDirectLine,
  contactNotWorkingMonday,
  contactNotWorkingTuesday,
  contactNotWorkingWednesday,
  contactNotWorkingThursday,
  contactNotWorkingFriday,
  contactWorkingHours,
  currentDiscipline,
  contactSlackUserId,
  contactBiography,
  sortBy,
  showDiscipline,
  lineManager,
  showTeam
}) {
  if (sortBy === "discipline" && contactDiscipline === currentDiscipline) {
    return (
      <Card
        contactName={contactName}
        contactRole={contactRole}
        contactImage={contactImage}
        contactDisciplineHead={contactDisciplineHead}
        contactEmail={contactEmail}
        contactDirectLine={contactDirectLine}
        contactManager={contactManager}
        contactTeam={contactTeam}
        contactDiscipline={contactDiscipline}
        showDiscipline={showDiscipline}
        showManagerBorder={showManagerBorder}
        showDiscipleHeadBorder={showDiscipleHeadBorder}
        contactNotWorkingMonday={contactNotWorkingMonday}
        contactNotWorkingTuesday={contactNotWorkingTuesday}
        contactNotWorkingWednesday={contactNotWorkingWednesday}
        contactNotWorkingThursday={contactNotWorkingThursday}
        contactNotWorkingFriday={contactNotWorkingFriday}
        contactWorkingHours={contactWorkingHours}
        contactSlackUserId={contactSlackUserId}
        contactBiography={contactBiography}
        showTeam={showTeam}
        lineManager={lineManager}
      />
    )
  } else if ( sortBy === "team" && contactTeam === currentTeam) {
    return (
      <Card
        contactName={contactName}
        contactRole={contactRole}
        contactImage={contactImage}
        contactEmail={contactEmail}
        contactDirectLine={contactDirectLine}
        contactManager={contactManager}
        contactDisciplineHead={contactDisciplineHead}
        showDiscipline={showDiscipline}
        showManagerBorder={showManagerBorder}
        showDiscipleHeadBorder={showDiscipleHeadBorder}
        contactTeam={contactTeam}
        contactDiscipline={contactDiscipline}
        contactNotWorkingMonday={contactNotWorkingMonday}
        contactNotWorkingTuesday={contactNotWorkingTuesday}
        contactNotWorkingWednesday={contactNotWorkingWednesday}
        contactNotWorkingThursday={contactNotWorkingThursday}
        contactNotWorkingFriday={contactNotWorkingFriday}
        contactWorkingHours={contactWorkingHours}
        contactSlackUserId={contactSlackUserId}
        contactBiography={contactBiography}
        lineManager={lineManager}
      />
    )
  }
  else {
    return (
      null
    )
  }
}
