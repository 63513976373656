import React from "react"
import { BiSearch } from "react-icons/bi"

export default function Search(){

  function searchFunction() {
    var input, filter, s_contact, teamTitles, a, i, j, sortedTeamWrapper, allContacts, jumpToTeams

    teamTitles = document.querySelectorAll(".team-name")
    s_contact = document.querySelectorAll("#allContacts .contact")

    sortedTeamWrapper = document.getElementById("sortedTeams")
    allContacts = document.getElementById("allContacts")
    jumpToTeams = document.getElementById("jumpToTeams")

    input = document.getElementById("searchInput")
    filter = input.value.toUpperCase()

    for (i = 0; i < s_contact.length; i++) {
      a = s_contact[i].dataset.contact
      if (a.toString().toUpperCase().indexOf(filter) > -1) {
        s_contact[i].style.display = ""
        sortedTeamWrapper.style.display = ""
        allContacts.style.display = "none"
        jumpToTeams.style.display = ""
        for (j = 0; j < teamTitles.length; j++) {
          teamTitles[j].style.display = ""
        }
      } else {
        s_contact[i].style.display = "none"
        sortedTeamWrapper.style.display = "none"
        allContacts.style.display = ""
        jumpToTeams.style.display = "none"
        for (j = 0; j < teamTitles.length; j++) {
          teamTitles[j].style.display = "none"
        }
      }
    }
  }

  return (
            <div class="inline-flex flex-row bg-white dark:text-white dark:bg-secondary-dark rounded-full overflow-hidden p-4 items-center gap-4 border border-opacity-50 border-grey-medium w-3/4">
          <BiSearch className="ml-2 dark:text-white" />
          <input
            type="text"
            id="searchInput"
            onKeyUp={searchFunction}
            placeholder="Search contacts..."
            className="bg-transparent focus:outline-none dark:placeholder-grey-medium w-full "
          />
        </div>
  )
}