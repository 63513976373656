import React from "react"
import { GatsbyImage, StaticImage } from "gatsby-plugin-image"
import { VscChromeClose } from "react-icons/vsc"
import { FaRegClock } from "react-icons/fa"
import { AiOutlineSlack, AiTwotoneCalendar, AiOutlineUser } from "react-icons/ai"
import { BiUserPin } from "react-icons/bi"
import { MdEmail, MdPhone } from "react-icons/md"
import { HTMLContent } from "../Content"
import useToggle from "../UseToggle"

export default function Card({
  contactName,
  contactRole,
  contactImage,
  contactDiscipline,
  contactEmail,
  contactDirectLine,
  contactTeam,
  contactManager,
  showManagerBorder,
  showDiscipleHeadBorder,
  contactDisciplineHead,
  contactNotWorkingMonday,
  contactNotWorkingTuesday,
  contactNotWorkingWednesday,
  contactNotWorkingThursday,
  contactNotWorkingFriday,
  contactWorkingHours,
  showDiscipline,
  contactBiography,
  lineManager,
  contactSlackUserId,
  showTeam
}) {
  const [showModal, showingModal] = useToggle(false)
  return (
    <div className="relative flex contact" data-contact={contactName.toUpperCase() + " " + contactRole.toUpperCase()}>
      <div
        className={
          "w-full shadow-xl rounded-xl overflow-hidden flex flex-col content-end dark:bg-secondary-dark text-left focus:outline-none transition duration-300" +
          (contactManager && showManagerBorder ? " border-primary-default border-t-4 " : null) +
          (contactDisciplineHead && showDiscipleHeadBorder ? " border-secondary-default border-t-4 " : null)
        }
      >
        <button className="block -mt-1 transition duration-300 hover:opacity-50" onClick={showingModal}>
          {contactImage ? (
            <GatsbyImage
              className="block contact-image"
              image={contactImage.gatsbyImageData}
              alt={contactName}
            />
          ) : (
            <StaticImage
              src={"https://placebear.com/g/800/800"}
              alt="Picture coming soon..."
              className="block"
            />
          )}
        </button>
        <div class="flex flex-col flex-grow">
          <div class="p-8">
            <h3 className="mb-4 text-lg text-center font-display lg:text-xl">{contactName}</h3>

            {showDiscipline ? <><h4 className="text-xs font-light text-center text-secondary-default dark:text-primary-default">{contactDiscipline}</h4></> : <h4 className="text-xs font-light text-center text-secondary-default dark:text-primary-default">{contactRole}</h4>}
            {showTeam ? <h5 className="mt-2 font-light text-center text-2xs text-secondary-dark dark:text-primary-light opacity-80">{contactTeam}</h5> : null}
          </div>


          <div className="flex items-center justify-between w-full gap-2 p-8 mt-auto border-t border-grey-medium border-opacity-10">

            <div className="inline-flex flex-row gap-2">

              {contactSlackUserId ? (
                <a
                  role="button"
                  aria-label="Direct message on Slack"
                  href={"slack://user?team=T025JCQ39&id=" + contactSlackUserId}
                  className="inline-flex text-white transition duration-300 rounded-full bg-primary-default hover:bg-white hover:text-black focus:bg-white focus:text-black hover:shadow-lg focus:shadow-lg"
                >
                  <AiOutlineSlack className="self-start p-1 mr-auto text-3xl" />
                </a>
              ) : null}

              {contactEmail ? (
                <a
                  role="button"
                  aria-label="Send an email"
                  href={"mailto:" + contactEmail}
                  className="inline-flex text-white transition duration-300 rounded-full bg-secondary-default hover:bg-white hover:text-black focus:bg-white focus:text-black hover:shadow-lg focus:shadow-lg "
                >
                  <MdEmail className="self-start p-3 mr-auto text-3xl" />
                </a>
              ) :
                <a
                  role="button"
                  aria-label="Send an email"
                  href={"mailto:" + (contactName.replace(" ", ".").toLowerCase()) + "@adtrak.co.uk"}
                  className="inline-flex text-white transition duration-300 rounded-full bg-secondary-default hover:bg-white hover:text-black focus:bg-white focus:text-black hover:shadow-lg focus:shadow-lg "
                >
                  <MdEmail className="self-start p-3 mr-auto text-3xl" />
                </a>}

              {contactDirectLine ? (
                <a
                  role="button"
                  aria-label="Send an email"
                  href={"tel:" + contactDirectLine}
                  className="inline-flex transition duration-300 rounded-full text-secondary-default bg-secondary-light hover:bg-white hover:text-black focus:bg-white focus:text-black hover:shadow-lg focus:shadow-lg "
                >
                  <MdPhone className="self-start p-3 mr-auto text-3xl" />
                </a>
              ) :
                null}

            </div>

            {contactWorkingHours ? (
              <span class="inline-flex text-2xs flex-row items-center space-x-2 font-light">
                <FaRegClock className="opacity-50" /> <span>{contactWorkingHours}</span>{" "}
              </span>
            ) : null}

          </div>

          {contactNotWorkingTuesday || contactNotWorkingMonday || contactNotWorkingWednesday || contactNotWorkingThursday || contactNotWorkingFriday ?

            <div class="absolute top-0 left-0 flex flex-col gap-2 mt-4 ml-4 text-3xs">
              {contactNotWorkingMonday ? (
                <span class="flex flex-row items-center space-x-2 bg-secondary-dark bg-opacity-40 text-white p-4 rounded-full">
                  <VscChromeClose className="text-red-500" />
                  <span>Mon</span>
                </span>
              ) : null}
              {contactNotWorkingTuesday ? (
                <span class="flex flex-row items-center space-x-2 bg-secondary-dark bg-opacity-40 text-white p-4 rounded-full">
                  <VscChromeClose className="text-red-500" />
                  <span>Tue</span>
                </span>
              ) : null}
              {contactNotWorkingWednesday ? (
                <span class="flex flex-row items-center space-x-2 bg-secondary-dark bg-opacity-40 text-white p-4 rounded-full">
                  <VscChromeClose className="text-red-500" />
                  <span>Wed</span>
                </span>
              ) : null}
              {contactNotWorkingThursday ? (
                <span class="flex flex-row items-center space-x-2 bg-secondary-dark bg-opacity-40 text-white p-4 rounded-full">
                  <VscChromeClose className="text-red-500" />
                  <span>Thu</span>
                </span>
              ) : null}
              {contactNotWorkingFriday ? (
                <span class="flex flex-row items-center space-x-2 bg-secondary-dark bg-opacity-40 text-white p-4 rounded-full">
                  <VscChromeClose className="text-red-500" />
                  <span>Fri</span>
                </span>
              ) : null}
            </div>

            : null}

        </div>
      </div>

      {/* Start modal */}

      <div className={"z-40 bottom-0 right-0 mb-4 mr-12 lg:hidden z-50 text-xs rounded-full flex border flex-row items-center text-center pointer-events-none bg-white px-6 py-3 border-grey-medium border-opacity-40 text-secondary-dark " +
        (showModal ? "fixed lg:hidden" : "hidden")}><VscChromeClose className="text-red-500" /> <span>Close</span></div>
      <div
        className={
          "inset-0 z-30 bg-secondary-dark p-8 lg:p-16 2xl:p-40 flex w-full h-full text-left overflow-y-auto bg-opacity-95 dark:bg-opacity-95 backdrop-filter backdrop-blur " +
          (showModal ? "fixed" : "hidden")
        }
      >
        <button
          onClick={showingModal}
          className="relative flex flex-col w-full max-w-screen-xl gap-16 p-8 m-auto bg-white group text-secondary-dark rounded-2xl lg:flex-row focus:outline-none lg:p-16 2xl:p-40"
        >
          <div className={"z-40 top-0 group-hover:bg-grey-light transition duration-300 right-0 mt-8 mr-8 text-xs rounded-full border flex-row items-center text-center pointer-events-none px-6 py-3 border-grey-medium border-opacity-40 text-secondary-dark hidden lg:flex absolute"}><VscChromeClose className="text-red-500" /> <span>Close</span></div>
          <div className="w-full mx-auto overflow-hidden bg-white shadow-xl max-w-screen-xs lg:w-1/2 rounded-xl">
            {contactImage ? (
              <GatsbyImage
                className="block w-full h-full mx-auto bg-cover contact-image "
                image={contactImage.gatsbyImageData}
                alt={contactName}
              />
            ) : (
              <StaticImage
                src={"https://placebear.com/g/800/800"}
                alt="Picture coming soon..."
                className="block mx-auto max-w-screen-xs contact-image "
              />
            )}
            <div className="p-8">
              <p className="mb-2 text-lg font-display text-secondary-dark">{contactName}</p>
              <p className="text-xs text-secondary-default">{contactRole}</p>
              {contactManager || contactDisciplineHead ?
                <div class="flex flex-row justify-center">
                  {contactManager ?
                    <p class="text-2xs flex flex-col items-center border border-primary-default text-primary-default w-auto inline-block mx-auto px-8 py-1 mt-8">
                      <span>Manager or Assistant</span>
                      <span>({contactTeam})</span>
                    </p>
                    : null}
                  {contactDisciplineHead ?
                    <p class="text-2xs flex flex-col items-center border border-secondary-default text-secondary-default w-auto inline-block mx-auto px-8 py-1 mt-8">
                      <span>Discipline Head</span>
                      <span>({contactDiscipline})</span>
                    </p>
                    : null}
                </div>
                : null}
            </div>
          </div>
          <div class="w-full lg:w-1/2 text-left">
            <div class="flex flex-col">


              {contactWorkingHours ? (
                <div class="mb-8">
                  <p className="flex flex-row items-center gap-6 mb-2 text-secondary-default"><FaRegClock /> <span>Working Hours</span></p>
                  <p className="text-lg">{contactWorkingHours}</p>
                </div>
              ) : null}


              <div class="mb-8">
                <p className="flex flex-row items-center gap-6 mb-2 text-secondary-default"><AiTwotoneCalendar /> <span>Working Days</span></p>

                <div className="flex flex-col gap-2 lg:flex-row">

                  <span class="flex flex-row">
                    {contactNotWorkingMonday ? (
                      <span className="line-through opacity-30">Monday,</span>
                    ) : (
                      <span>Monday,</span>
                    )}

                  </span>
                  <span class="flex flex-row">
                    {contactNotWorkingTuesday ? (
                      <span className="line-through opacity-30">Tuesday,</span>
                    ) : (
                      <span>Tuesday,</span>
                    )}
                  </span>
                  <span class="flex flex-row">
                    {contactNotWorkingWednesday ? (
                      <span className="line-through opacity-30">Wednesday,</span>
                    ) : (
                      <span>Wednesday,</span>
                    )}
                  </span>
                  <span class="flex flex-row">
                    {contactNotWorkingThursday ? (
                      <span className="line-through opacity-30">Thursday,</span>
                    ) : (
                      <span>Thursday,</span>
                    )}
                  </span>
                  <span class="flex flex-row">
                    {contactNotWorkingFriday ? (
                      <span className="line-through opacity-30">Friday</span>
                    ) : (
                      <span className="">Friday</span>
                    )}
                  </span>

                </div>

              </div>

            </div>

            {contactBiography ?
              <div class="mb-8">

                <p className="flex flex-row items-center gap-6 mb-2 text-secondary-default"><BiUserPin /> <span>About {contactName}</span></p>
                <HTMLContent className="content" content={contactBiography} />

              </div>

              : null}

            {lineManager ?
              <div class="mb-8">

                <p className="flex flex-row items-center gap-6 mb-2 text-secondary-default"><AiOutlineUser /> <span>Line Manager</span></p>
                <p>{lineManager}</p>

              </div>

              : null}



            {contactEmail || contactSlackUserId ?
              <div className="inline-flex flex-row gap-4 mt-8">

                {contactSlackUserId ? (
                  <a
                    role="button"
                    aria-label="Direct message on Slack"
                    href={"slack://user?team=T025JCQ39&id=" + contactSlackUserId}
                    className="inline-flex items-center gap-4 p-4 pr-6 text-white transition duration-300 rounded-full bg-primary-default hover:bg-secondary-dark focus:bg-secondary-dark"
                  >
                    <AiOutlineSlack className="self-start p-1 p-2 mr-auto text-3xl bg-white rounded-full text-primary-default" /> <span>Slack</span>
                  </a>
                ) : null}

                {contactEmail ? (

                  <a
                    role="button"
                    aria-label="Send an email"
                    href={"mailto:" + contactEmail}
                    className="inline-flex items-center gap-4 p-4 pr-6 text-white transition duration-300 rounded-full text-secondary-dark bg-secondary-default hover:bg-secondary-dark focus:bg-secondary-dark hover:text-white"
                  >
                    <MdEmail className="self-start p-1 p-2 mr-auto text-3xl bg-white rounded-full text-secondary-default" /> <span>Email</span>
                  </a>
                ) : <a
                  role="button"
                  aria-label="Send an email"
                  href={"mailto:" + (contactName.replace(" ", ".").toLowerCase()) + "@adtrak.co.uk"}
                  className="inline-flex items-center gap-4 p-4 pr-6 text-white transition duration-300 rounded-full text-secondary-dark bg-secondary-default hover:bg-secondary-dark focus:bg-secondary-dark hover:text-white"
                >
                  <MdEmail className="self-start p-1 p-2 mr-auto text-3xl bg-white rounded-full text-secondary-default" /> <span>Email</span>
                </a>}

                

              </div>
              : null}


          </div>
        </button>
      </div>
    </div>
  )
}
